export const environment = {
  name: 'production',
  development: false,
  staging: false,
  production: true,
  apiDomain: 'https://node-api.fundmarket.dk',
  apiUrl: 'https://node-api.fundmarket.dk/investor',
  webUrl: 'https://fundmarket.dk/wp-content/uploads',
  fundUrl: 'https://node-api.fundmarket.dk/funds',
  enableDebug: false,
  host: "https://fundmarket.criipto.id",
  post_logout_redirect_uri: 'https://investor.fundmarket.dk/login',
};
